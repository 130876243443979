$mol_embed_youtube $mol_check
	uri \
	video_preview \
	video_id \
	checked? <=> active? false
	sub /
		<= Image $mol_image
			title <= title \
			uri <= video_preview \
		<= Hint $mol_icon_youtube
		<= Frame $mol_frame
			title <= title \
			uri <= video_embed \

