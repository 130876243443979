$mol_button $mol_view
	enabled true
	click?event null
	event_click?event null
	event *
		^
		click?event <=> event_activate?event null
		dblclick?event <=> clicks?event null
		keydown?event <=> event_key_press?event null 
	attr *
		^
		disabled <= disabled false
		role \button
		tabindex <= tab_index 0
		title <= hint_safe <= hint \
	sub /$mol_view_content
		<= title
	Speck $mol_speck
		value <= error \
