$mol_image $mol_view
	dom_name \img
	field *any
		^
		src <= uri \
		alt <= title
		loading <= loading \eager
		decoding <= decoding \async
		crossOrigin <= cors null
	attr *any
		^
		width <= natural_width 0
		height <= natural_height 0
	event *any
		load? <=> load? null
	minimal_width 16
	minimal_height 16
