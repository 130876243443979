$mol_grid $mol_view
	row_height 32
	row_ids /string[]
	row_id* null
	col_ids /
	records *
	record* null
	hierarchy null
	hierarchy_col \
	minimal_width 0
	-
	sub /
		<= Head
		<= Table $mol_grid_table
			sub <= rows /$mol_view
	Head $mol_grid_row
		cells <= head_cells /$mol_view
	Row* $mol_grid_row
		minimal_height <= row_height
		minimal_width <= minimal_width
		cells <= cells* /$mol_view
	-
	Cell* $mol_view
	cell* null
	Cell_text* $mol_grid_cell
		sub <= cell_content_text* <= cell_content* /$mol_view_content
	Cell_number* $mol_grid_number
		sub <= cell_content_number* <= cell_content* /$mol_view_content
	Col_head* $mol_float
		dom_name \th
		sub <= col_head_content* /$mol_view_content
	Cell_branch* $mol_check_expand
		level <= cell_level* 0
		label <= cell_content* /$mol_view_content
		expanded? <=> cell_expanded*? false
	Cell_content* /
		<= Cell_dimmer* $mol_dimmer
			needle <= needle \
			haystack <= cell_value* \

$mol_grid_table $mol_list
	- dom_name \table

$mol_grid_row $mol_view
	- dom_name \tr
	sub <= cells /$mol_view

$mol_grid_cell $mol_view
	- dom_name \td
	minimal_height 40

$mol_grid_number $mol_grid_cell
