$mol_embed_any $mol_view
	Image $mol_image
		title <= title \
		uri <= uri \
	Object $mol_embed_native
		title <= title \
		uri <= uri \
	Youtube $mol_embed_youtube
		title <= title \
		uri <= uri \
