$mol_text_list $mol_text
	auto_scroll null
	attr *
		^
		mol_text_list_type <= type \
	Paragraph* $mol_text_list_item
		index <= item_index*
		sub <= block_content*

$mol_text_list_item $mol_paragraph
	attr *
		^
		mol_text_list_item_index <= index 0
