$mol_dimmer $mol_paragraph
	haystack \
	needle \
	sub <= parts /$mol_view_content
	Low* $mol_paragraph
		sub /
			<= string* \
	High* $mol_paragraph
		sub /
			<= string* \
