$mol_embed_native $mol_scroll
	uri? \
	dom_name \object
	window null
	attr *
		^
		data <= uri
		type <= mime \
	sub /
		<= Fallback $mol_link
			uri <= uri
			sub /
				<= title \
	message *
		hashchange? <=> uri_change? null
