$mol_check $mol_button_minor
	attr *
		^
		mol_check_checked <= checked? false
		aria-checked <= aria_checked \false
		role <= aria_role \checkbox
	sub /$mol_view_content
		<= Icon null
		<= label /
			<= Title $mol_view
				sub /
					<= title \
