$mol_link $mol_view
	uri \
	dom_name \a
	uri_off \
	uri_native null
	external false
	attr *
		^
		href <= uri_toggle \
		title <= hint_safe <= hint \
		target <= target \_self
		download <= file_name \
		mol_link_current <= current false
		rel <= relation \
	sub /$mol_view_content
		<= title
	arg *
	event *
		^
		click?event <=> click?event <=> event_click?event null
