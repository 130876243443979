$mol_text_code_token $mol_dimmer
	attr *
		^
		mol_text_code_token_type <= type \

$mol_text_code_token_link $mol_text_code_token
	dom_name \a
	type \code-link
	attr *
		^
		href <= uri \
		target \_blank
