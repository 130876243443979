$mol_list $mol_view
	render_visible_only true
	render_over 0
	sub <= rows /$mol_view
	Empty $mol_view
	Gap_before $mol_view style * paddingTop <= gap_before 0
	Gap_after $mol_view style * paddingTop <= gap_after 0
	view_window /
		0
		0
