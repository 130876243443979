$mol_text_code_row $mol_paragraph
	text \
	minimal_height 24
	numb_showed true
	syntax null
	uri_resolve* \
	Numb $mol_view
		sub /
			<= numb 0
	Token* $mol_text_code_token
		type <= token_type* \
		haystack <= token_text* \
		needle <= highlight \
	Token_link* $mol_text_code_token_link
		haystack <= token_text* \
		needle <= highlight \
		uri <= token_uri* \
	find_pos* null
