$mol_string $mol_view
	dom_name \input
	enabled true
	minimal_height 40
	autocomplete false
	selection? /number
		0
		0
	auto /
		<= selection_watcher null
		<= error_report null
	field *
		^
		disabled <= disabled false
		value <= value_changed? <=> value? \
		placeholder <= hint_visible <= hint \
		spellcheck <= spellcheck true
		autocomplete <= autocomplete_native \
		selectionEnd <= selection_end 0
		selectionStart <= selection_start 0
		inputMode <= keyboard \text
		enterkeyhint <= enter \go
	attr *
		^
		maxlength <= length_max +Infinity
		type <= type? \text
	event *
		^
		input?event <=> event_change?event null
	plugins /
		<= Submit $mol_hotkey
			mod_ctrl <= submit_with_ctrl false
			key * enter?event <=> submit?event null
